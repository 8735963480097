<template>
  <div v-if="risk && risk.riskValue != null" :class="riskClass">
    <svg :class="`mr-1 ${svgClass} h-${dotSize.height} w-${dotSize.width} fill-current rounded-full border-2 border-white`" viewBox="0 0 10 10" xmlns="http://www.w3.org/2000/svg">
      <circle cx="5" cy="5" r="5" />
    </svg>
    <span :class="`text-gray-800 ${svgClass} ${spanClass}`">{{ risk?.title }}</span>
  </div>
  <div v-else :class="pendingClass">
    <span :class="[badgeStyle === 'large' ? 'text-gray-800 font-bold' : null]">{{ risk?.title ?? 'Pending'}}</span>
  </div>
</template>

<script>
import { DashboardRisk, DashboardRiskBadgeStyleType } from '@/models/dashboard';

export default {
  name: 'DashboardRiskBadge',
  props: {
    risk: {
      type: DashboardRisk
    },
    badgeStyle: {
      type: String,
      required: true,
      validator: (value) => Object.values(DashboardRiskBadgeStyleType).includes(value)
    }
  },
  computed: {
    svgClass() {
      if (this.badgeStyle === DashboardRiskBadgeStyleType.large) {
        return '';
      }
      return 'inline-block align-middle';
    },
    spanClass() {
      if (this.badgeStyle !== DashboardRiskBadgeStyleType.large) {
        return '';
      }
      return 'font-bold';
    },
    riskClass() {
      switch (this.badgeStyle) {
      case DashboardRiskBadgeStyleType.small: return `inline-block align-middle text-xs ${this.risk?.color}`;
      case DashboardRiskBadgeStyleType.medium: return `inline-block align-middle p-1 px-3 pl-2 rounded-full ${this.risk?.backgroundColor} text-xs ${this.risk?.color}`;
      case DashboardRiskBadgeStyleType.large: return `inline-flex items-center py-1 px-4 pl-2 rounded-full ${this.risk?.backgroundColor} text-lg ${this.risk?.color} border ${this.risk?.borderColor}`;
      default: return null;
      }
    },
    dotSize() {
      switch (this.badgeStyle) {
      case DashboardRiskBadgeStyleType.small: return { height: 3, width: 3 };
      case DashboardRiskBadgeStyleType.medium: return { height: 3, width: 3 };
      case DashboardRiskBadgeStyleType.large: return { height: 4, width: 4 };
      default: return { height: null, width: null };
      }
    },
    pendingClass() {
      switch (this.badgeStyle) {
      case DashboardRiskBadgeStyleType.small, DashboardRiskBadgeStyleType.medium: return 'inline-block align-middle p-1 font-medium px-3 rounded-full align-middle bg-gray-100 text-xs text-gray-600';
      case DashboardRiskBadgeStyleType.large: return 'inline-flex items-center py-1 px-4 rounded-full align-middle bg-gray-200 text-lg text-gray-500 border border-gray-400';
      default: return null;
      }
    }
  }
};
</script>

<style>
</style>

<template>
  <div>
    <div v-if="configuration == null" class="h-24 flex relative mt-1 rounded-lg p-3 bg-gray-100 border-2 border-dashed border-gray-400">
      <h5 class="w-5 h-5 text-center leading-5 rounded-full font-semibold text-xs absolute -top-2 -left-2 text-white bg-gray-600">A</h5>

      <button @click="$emit('add')" class="block text-pink-600 mx-auto">
        + <span class="underline">Add configuration</span>
      </button>
    </div>


    <div v-else
      class="relative mt-5 rounded-lg p-3 bg-white border-gray-400 card-border"
      :class="[isSelected ? 'border-2 border-gray-600 card-border-selected' : 'border border-gray-400']"
    >
      <h5 class="w-5 h-5 text-center leading-5 rounded-full font-semibold text-xs absolute -top-2 -left-2 text-white bg-gray-600">{{ letter }}</h5>
      <header class="pr-8 flex flex-wrap items-center mb-2">
        <!-- Card title-->
        <button class="select-card text-sm font-normal mr-2 align-middle">ID <span class="font-bold">{{ configuration.id }}</span></button>
        <!-- Risk rating -->
        <dashboard-risk-badge :risk="configuration.risk" :badgeStyle="'small'" />
        <Menu as="div" class="absolute right-2 top-2">
          <div>
            <div v-if="isLoading" class="grid place-content-center z-50 w-full h-full">
              <svg class="animate-spin" width="16" height="16" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="13.25" cy="12.75" r="10.75" stroke="#F9CFE1" stroke-width="4"/>
              <path d="M24 12.75C24 6.81294 19.1871 2 13.25 2C7.31294 2 2.5 6.81294 2.5 12.75C2.5 18.6871 7.31294 23.5 13.25 23.5" stroke="#F42272" stroke-width="4" stroke-linecap="round"/>
              </svg>
            </div>
            <MenuButton v-else class="bg-gray-100 ml-auto p-0.5 rounded-full flex items-center text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-gray-500 print:hidden" id="menu-button" aria-expanded="true" aria-haspopup="true">
              <span class="sr-only">Open options</span>
              <DotsVerticalIcon class="h-4 w-4" />
            </MenuButton>
          </div>
          <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
            <MenuItems class="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none" role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabindex="-1">
              <div class="py-1" role="none">
                <MenuItem as="button" @click="$emit('remove')" class='text-gray-700 px-4 py-2 text-sm text-left hover:bg-gray-100 cursor-pointer w-full' id="menu-item-0">Remove configuration</MenuItem>
                <MenuItem as="button" @click="$emit('swap')" class='text-gray-700 block px-4 py-2 text-sm text-left hover:bg-gray-100 cursor-pointer w-full' id="menu-item-1">Swap with current</MenuItem>
              </div>
            </MenuItems>
          </transition>
        </Menu>
        <!-- kebab button container -->
      </header>
      <main class="flex flex-wrap items-baseline">
        <dl v-for="setting in configuration.settings" :key="setting.key" class="m-2 flex-1" :title="setting.fullLabel">
          <dd :class="`text-sm font-bold ${detailColor(setting)}`">{{ setting.propertyLabel }}</dd>
          <dt :class="`text-xs font-normal ${titleColor(setting)}`">{{ setting.shortLabel }}</dt>
        </dl>
      </main>
    </div>
  </div>
</template>

<script>
import { DashboardConfiguration } from '@/models/dashboard';
import DashboardRiskBadge from './DashboardRiskBadge.vue';
import {
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
} from '@headlessui/vue';
import { DotsVerticalIcon } from '@heroicons/vue/solid';

export default {
  name: 'DashboardConfigurationCard',
  components: {
    DashboardRiskBadge,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    DotsVerticalIcon
  },
  props: {
    configuration: {
      type: DashboardConfiguration,
      required: false
    },
    currentConfiguration: {
      type: DashboardConfiguration,
      required: false
    },
    isSelected: {
      type: Boolean,
      required: true
    },
    isLoading: {
      type: Boolean,
      required: true
    },
    letter: {
      type: String,
      required: true
    }
  },
  methods: {
    detailColor(setting) {
      return this.currentConfiguration?.isEqual(setting) ? 'text-gray-300' : 'text-gray-800';
    },
    titleColor(setting) {
      return this.currentConfiguration?.isEqual(setting) ? 'text-gray-200' : 'text-gray-400';
    },
  }
};
</script>

<style>
.card-border:hover {
  box-shadow: 0 0 0 4px rgba(106, 119, 128, 0.1);
}

.card-border-selected, .card-border-selected:hover {
  box-shadow: 0 0 0 4px rgba(106, 119, 128, 0.2);
}

.select-card:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}
</style>
